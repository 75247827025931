import { MacButton } from "components";
import { ModalFooter } from "components/modal";

interface AddCityActionsProps {
  onDismiss: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting?: boolean;
}
export const AddCityActions = ({ onDismiss, isSubmitting }: AddCityActionsProps) => {
  return (
    <ModalFooter align="right" className="bg-white px-0 md:px-0 lg:px-0 w-full mt-10">
      <MacButton className="bg-default border-none text-primary " type="submit" onClick={() => onDismiss(false)}>
        Cancel
      </MacButton>
      <MacButton disabled={isSubmitting} variant="primary" type="submit">
        Save
      </MacButton>
    </ModalFooter>
  );
};
