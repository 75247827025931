import { BitmapLayer, GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import { ICON_MAPPING, PrivateParkingModalState, initPrivateParking } from "./private-parking.const";
import { ModeViewType, mapboxStyle } from "constants/map.const";
import { PrivateParkingTooltip, parkingTooltipProps } from "./components/private-parking-tooltip";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

import { CityContext } from "contexts/city-context-provider";
import DeckGL from "@deck.gl/react";
import { IPrivateParking } from "types";
import { MAPBOX_ACCESS_TOKEN } from "constants/mapbox.const";
import MapToolBox from "./components/map-tool-box";
import { Position } from "geojson";
import { PrivateParkingDetail } from "./components/private-parking-detail";
import { PrivateParkingModal } from "./modes/add-offstreet-parking/private-parking-modal";
import { Map as StaticMap } from "react-map-gl";
import { SearchPlace, SelectMapType, SwitchModeView } from "../curb-zone/components";
import { MapType, ViewportPlaceType, interactionStateType, mapUrlByType } from "types/map.type";
import { isNil } from "helper/utils";
import { useAddControlMapbox } from "hooks/use-add-control-mapbox";
import { usePrivateParking } from "./private-parking.hook";
import { useQueryParamByViewPort } from "hooks/use-set-query-params";
import { TileLayer } from "deck.gl";
import { createMarkerLayer } from "helper/map-utils";

export const PrivateParkingMap = () => {
  const { currentCity, viewport, setViewport } = useContext(CityContext);
  const [mapType, setMapType] = useState<MapType>(MapType.MAPBOX);
  const [parkingModalState, setParkingModalState] = useState<keyof typeof PrivateParkingModalState>(
    PrivateParkingModalState.DEACTIVATE,
  );

  const [modeView, setModeView] = useState<ModeViewType>("normal");
  const [privateParkingDetail, setPrivateParkingDetail] = useState<IPrivateParking | null>();

  const currentPosition = useRef<Position>([]);
  const mapRef = useRef<any>();
  const preZooming = useRef<boolean>(false);
  const { mapRefCallback } = useAddControlMapbox();
  const setQueryParamByViewport = useQueryParamByViewPort();

  const { setBounding, privateParking, createPrivateParking, updatePrivateParking, removePrivateParking } =
    usePrivateParking();

  const [hoverInfo, setHoverInfo] = useState<parkingTooltipProps>({} as parkingTooltipProps);

  const mapStyle = useMemo(() => mapboxStyle[modeView], [modeView]);
  useEffect(() => {
    if (isNil(currentCity)) return;
    if (currentCity?.position) {
      setPrivateParkingDetail(initPrivateParking);
      currentPosition.current = currentCity?.position;
    }
  }, [currentCity]);

  useEffect(() => {
    if (!currentCity) {
      return;
    }
    getPrivateParkings(viewport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewport, currentCity]);

  const privateParkingPoint = useMemo(() => {
    return privateParking.map(({ location, ...rest }: IPrivateParking) => ({
      ...rest,
      coordinates: location.coordinates,
    }));
  }, [privateParking]);

  const privateParkingLayer = new IconLayer({
    id: "private-icon-layer",
    data: privateParkingPoint,
    pickable: true,
    iconAtlas: "images/private_parking_icon.png",
    iconMapping: ICON_MAPPING,
    getIcon: () => "marker",
    sizeScale: 15,
    getPosition: (d: any) => d.coordinates,
    getSize: () => 3,
    onHover: (info: any) => {
      if (info.object) {
        if (
          !hoverInfo.privateParking ||
          (hoverInfo?.privateParking && info.object.id !== hoverInfo?.privateParking.id)
        ) {
          setHoverInfo({
            left: info.x,
            top: info.y,
            privateParking: info.object,
          });
        }
      } else if (!info.object) {
        setHoverInfo({} as parkingTooltipProps);
      }
    },
  });

  const markerLayer = useMemo(() => {
    if (!privateParkingDetail?.location?.coordinates?.length) {
      return null;
    }
    return createMarkerLayer({
      coordinates: [privateParkingDetail.location.coordinates[0], privateParkingDetail.location.coordinates[1]],
    });
  }, [privateParkingDetail]);

  const currentCityLayer = new GeoJsonLayer({
    id: "current-city",
    data: currentCity?.boundaries,
    filled: false,
    lineWidthMinPixels: 1,
    lineWidthMaxPixels: 4,
  });

  const tileLayer = new TileLayer({
    id: "sat-other-map",
    data: mapUrlByType[mapType],

    minZoom: 0,
    maxZoom: 19,
    tileSize: 256,

    renderSubLayers: ({ tile, data, ...props }: any) => {
      const {
        bbox: { west, south, east, north },
      } = tile;

      return new BitmapLayer(props, {
        image: data,
        bounds: [west, south, east, north],
      });
    },
    visible: mapType !== MapType.MAPBOX,
  });

  const privateParkingMapLayers = [tileLayer, privateParkingLayer, currentCityLayer, markerLayer];

  const getCursor = () => {
    return ({ isDragging }: { isDragging: boolean }) => (isDragging ? "grabbing" : "grab");
  };

  const handleClickAdd = () => {
    setParkingModalState(PrivateParkingModalState.SHOW);
  };

  const handleDrapEnd = (event: any) => {
    const { viewport } = event;
    const newPosition = [viewport?.longitude, viewport?.latitude];
    currentPosition.current = [...newPosition];
    getPrivateParkings(viewport);
  };

  const getPrivateParkings = (viewState: any) => {
    if (!currentCity || !viewState) return;
    setQueryParamByViewport({ viewState, viewport, setBounding, cityID: currentCity.id });
  };

  const handleZooming = (input: interactionStateType) => {
    if (preZooming.current && !input.isZooming) {
      getPrivateParkings(preZooming.current);
    }
  };

  const onViewStateChange = ({ viewState }: any) => {
    preZooming.current = viewState;
  };

  const selectAddress = (viewport: ViewportPlaceType | null) => {
    if (viewport) {
      setPrivateParkingDetail(
        (prevState) =>
          ({
            ...prevState,
            address: viewport.name,
            location: { type: "Point", coordinates: [Number(viewport.longitude), Number(viewport.latitude)] },
          }) as IPrivateParking,
      );

      setViewport((prevState) => ({ ...prevState, ...viewport, zoom: 16 }));
    }
  };

  const handleSelectPrivateParking = (info: any) => {
    if (parkingModalState === PrivateParkingModalState.MINIMIZE) {
      setPrivateParkingDetail({
        ...privateParkingDetail,
        location: { type: "Point", coordinates: info.coordinate },
      } as IPrivateParking);
      return;
    }
    if (info.object && info.object.id) {
      const { coordinates, ...rest } = info.object;
      rest["location"] = { type: "Point", coordinates };
      setPrivateParkingDetail(rest);
      return;
    }
  };

  const handleClose = () => {
    setParkingModalState(PrivateParkingModalState.DEACTIVATE);
    setPrivateParkingDetail(initPrivateParking);
  };

  const savePrivateParking = async (input: IPrivateParking) => {
    const totalPlaces = Number(input.totalPlaces);
    const pricePerHour = Number(input.pricePerHour);
    const newPrivateParking = { ...input, cityId: currentCity?.id, totalPlaces, pricePerHour } as IPrivateParking;
    if (input.id) {
      await updatePrivateParking({ variables: { updatePrivateParkingInput: newPrivateParking } });
    } else {
      await createPrivateParking({ variables: { createPrivateParkingInput: newPrivateParking } });
    }
    handleClose();
  };

  const handleRemovePrivateParking = async () => {
    await removePrivateParking({ variables: { parkingId: privateParkingDetail?.id } });
    setPrivateParkingDetail(initPrivateParking);
  };

  return (
    <div className="relative h-full">
      <DeckGL
        initialViewState={{ ...viewport }}
        controller={{
          touchZoom: true,
          touchRotate: true,
        }}
        getCursor={getCursor()}
        layers={privateParkingMapLayers}
        pickingRadius={12}
        onDragEnd={handleDrapEnd}
        onInteractionStateChange={handleZooming}
        ref={mapRef}
        onClick={handleSelectPrivateParking}
        onViewStateChange={onViewStateChange}
      >
        <StaticMap mapboxAccessToken={MAPBOX_ACCESS_TOKEN} mapStyle={mapStyle} ref={mapRefCallback} />
        {parkingModalState !== PrivateParkingModalState.MINIMIZE && hoverInfo?.privateParking && (
          <PrivateParkingTooltip left={hoverInfo.left} top={hoverInfo.top} privateParking={hoverInfo.privateParking} />
        )}
      </DeckGL>
      <div className="absolute top-0 left-6 z-6 flex gap-2">
        <SearchPlace onSelect={selectAddress} coordinates={viewport} />
        <SelectMapType mapType={mapType} onSelectMapType={(value) => setMapType(value)} />
      </div>
      <SwitchModeView modeView={modeView} selectModeView={(mode: ModeViewType) => setModeView(mode)} />
      <div className="absolute top-60 left-8 z-6">
        <MapToolBox onClickAdd={handleClickAdd} disabled={parkingModalState !== PrivateParkingModalState.DEACTIVATE} />
      </div>
      {parkingModalState !== PrivateParkingModalState.DEACTIVATE && privateParkingDetail && (
        <PrivateParkingModal
          coordinates={viewport}
          privateParking={privateParkingDetail}
          onClose={handleClose}
          onSave={savePrivateParking}
          parkingModalState={parkingModalState}
          setParkingModalState={setParkingModalState}
          setPrivateParkingDetail={setPrivateParkingDetail}
        />
      )}

      {parkingModalState !== PrivateParkingModalState.MINIMIZE && privateParkingDetail && privateParkingDetail.id && (
        <PrivateParkingDetail
          setParkingModalState={setParkingModalState}
          parking={privateParkingDetail}
          removeParking={handleRemovePrivateParking}
        />
      )}
    </div>
  );
};
